<template>
  <img
    v-if="passwordTypeModel === 'text'"
    alt="Показать"
    class="img--hoar"
    src="@/modules/user/icons/eye.svg"
    @click="togglePasswordType"
  />
  <img
    v-else
    alt="Скрыть"
    class="img--hoar"
    src="@/modules/user/icons/eye-close.svg"
    @click="togglePasswordType"
  />
</template>

<script lang="ts" setup>
const passwordTypeModel = defineModel<"password" | "text">({ required: true });

const togglePasswordType = () => {
  passwordTypeModel.value =
    passwordTypeModel.value === "password" ? "text" : "password";
};
</script>

<style lang="scss" scoped></style>
