import { defineStore } from "pinia";
import { type Ref, ref } from "vue";
import type { SidebarType } from "@/layouts/sidebars/sidebars.types";

interface SidebarStore {
  currentSidebars: Ref<SidebarType[]>;
  isFullScreen: Ref<boolean>;
  toggleFullScreen: () => void;
  goBack: () => void;
}

const currentSidebars = ref<SidebarType[]>([]);

export const useSidebarStore = defineStore("sidebar", (): SidebarStore => {
  const goBack = () => {
    currentSidebars.value.pop();
  };

  const isFullScreen = ref(false);

  const toggleFullScreen = () => {
    isFullScreen.value = !isFullScreen.value;
  };

  return {
    currentSidebars,
    isFullScreen,
    toggleFullScreen,
    goBack,
  };
});
