<template>
  <div>
    <div
      class="flex h-[52px] min-h-[52px] w-full items-center justify-between border-b border-lunar bg-white px-5"
    >
      <div class="flex gap-5">
        <app-button
          v-if="
            route.meta.sidebars &&
            route.meta.sidebars.includes(SidebarType.NONE)
          "
          size="medium"
          type="half-contained"
          variant="tertiary"
          @click="router.push(`/${Route.KNOWLEDGE_BASE}`)"
        >
          <img
            alt="<"
            class="img--hoar -ml-1 h-[18px] w-[18px] rotate-180"
            src="@/assets/icons/direction/arrow-right-simple.svg"
          />
          <span>Вернуться назад</span>
        </app-button>
        <button v-if="shouldShowLogo">
          <img
            alt="partner"
            class="h-[36px]"
            src="@/assets/logos/partner.png"
          />
        </button>
      </div>
      <div class="flex gap-3">
        <button
          class="flex h-[36px] items-center gap-2 rounded-[10px] bg-hoar/10 px-4"
          @click="balanceDialogStore.isOpen = true"
        >
          <img
            alt=""
            class="img--primary w-[18px]"
            src="@/assets/icons/plus-circle-filled.svg"
          />
          <span class="text-13 font-semibold text-base"
            >{{ Number(userStore.user?.balance || 0).toLocaleString() }} ₽</span
          >
        </button>
        <app-popover placement="bottom">
          <template #default>
            <button
              class="flex h-9 items-center justify-center gap-1.5 rounded-[10px] bg-hoar/10 px-1"
            >
              <span
                class="-mt-0.5 flex h-7 w-7 items-center justify-center rounded-[10px] bg-hoar/50"
              >
                <img
                  alt=""
                  class="img--white mt-2"
                  src="@/assets/icons/user/user-main.svg"
                />
              </span>
              <img
                alt=""
                class="img--hoar mr-0.5 h-[14px] w-[14px]"
                src="@/assets/icons/direction/arrow-bottom-simple.svg"
              />
            </button>
          </template>
          <template #content="{ close }">
            <div class="pr-[13px]">
              <div
                class="flex w-[260px] flex-col items-center rounded-[10px] border border-lunar bg-white pt-3 shadow-light"
              >
                <div
                  class="relative mb-2 flex h-[52px] w-[52px] justify-center overflow-hidden rounded-full bg-hoar/25"
                >
                  <img
                    alt=""
                    class="img--white absolute -bottom-1 h-[44px] w-[41px]"
                    src="@/assets/icons/user/user-main.svg"
                  />
                </div>
                <h2 class="text-14 font-semibold text-base">
                  {{ userName ? userName : userStore.user?.email }}
                </h2>
                <p class="text-13 font-medium text-hoar">
                  <!--                {{ userStore.user. }}-->
                  <!--                •-->
                  {{ userName ? `${userStore.user?.email}` : "" }}
                </p>
                <div class="mt-3 w-full border-t border-lunar p-[6px]">
                  <button
                    class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                    @click="
                      () => {
                        close();
                        router.push(
                          userStore.user?.owner
                            ? `/${Route.ADMIN_PROFILE}`
                            : `/${Route.USER_PROFILE}`,
                        );
                      }
                    "
                  >
                    <img
                      alt=""
                      class="img--hoar h-[18px] w-[18px]"
                      src="@/assets/icons/user/user-circle.svg"
                    />
                    Профиль
                  </button>
                </div>
                <div
                  v-if="userStore.user?.owner"
                  class="w-full border-t border-lunar p-[6px]"
                >
                  <button
                    class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                  >
                    <img
                      alt=""
                      class="img--hoar h-[18px] w-[18px]"
                      src="@/assets/icons/message.svg"
                    />
                    Тех. поддержка
                  </button>
                </div>
                <div class="flex w-full gap-2 border-t border-lunar p-[6px]">
                  <button
                    class="group flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] hover:bg-hoar/10"
                    @click="handleLogOut(close)"
                  >
                    <img
                      alt=""
                      class="img--hoar group-hover:img--tomato h-[18px] w-[18px]"
                      src="@/assets/icons/open-door-outlined.svg"
                    />
                    <span
                      class="text-13 font-semibold text-hoar group-hover:text-tomato"
                    >
                      Выйти
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </app-popover>
      </div>
    </div>
    <logout-confirm-dialog v-model="isLogoutConfirmVisible" />
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { Route } from "@/router/router.types";
import { computed, ref } from "vue";
import LogoutConfirmDialog from "@/modules/user-cabinet/views/profile/components/dialogs/logout-confirm-dialog.vue";
import { useUserStore } from "@/stores/user/user";
import { useBalanceDialogStore } from "@/stores/dialogs/balance-dialog";
import AppPopover from "@/shared/UIKit/app-popover.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";

const route = useRoute();
const router = useRouter();
const isLogoutConfirmVisible = ref(false);
const userStore = useUserStore();
const balanceDialogStore = useBalanceDialogStore();

const emit = defineEmits<{
  "add-project": [];
}>();

const handleLogOut = (close: () => void) => {
  close();
  isLogoutConfirmVisible.value = true;
};

const shouldShowLogo = computed(() => {
  return (
    route.name !== Route.ADMIN_PROFILE && route.name !== Route.USER_PROFILE
  );
});

const userName = computed(() => {
  if (!userStore.user) {
    return "";
  }

  return userStore.user.firstName;
});
</script>

<style lang="scss" scoped>
::v-deep(.app-popover-new) {
  .popper {
    #arrow[data-v-20b7fd4a] {
      --popper-theme-background-color: #ffffff;
      --popper-theme-background-color-hover: #ffffff;
      --popper-theme-border-width: 1px;
      --popper-theme-border-style: solid;
      --popper-theme-border-color: #e3e9f5;
      top: -4px !important;

      &::before {
        @apply border-b-0 border-l border-r-0 border-t border-solid border-lunar #{!important};
      }
    }
  }
}
</style>
