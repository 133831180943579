import { Route } from "@/router/router.types";
import { userRoutes } from "@/modules/user/router/user.routes";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";

export const routes = [
  ...userRoutes,
  {
    path: "",
    name: "",
    redirect: `/${Route.KNOWLEDGE_BASE}`,
  },
  {
    path: `/${Route.KNOWLEDGE_BASE}`,
    name: Route.KNOWLEDGE_BASE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/knowledge-base-tree/knowledge-base-tree-view.vue"
      ),
  },
  {
    path: `/${Route.PAYMENT_SUCCESS}`,
    name: Route.PAYMENT_SUCCESS,
    meta: {
      authRequired: true,
      sidebars: [],
      layout: "empty",
    },
    component: () => import("@/views/payment-success/payment-success-view.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_ADMIN}`,
    name: Route.KNOWLEDGE_BASE_ADMIN,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/admin/views/knowledge-base-tree/admin-knowledge-base-tree-view.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_ADMIN_TESTING}`,
    name: Route.KNOWLEDGE_BASE_ADMIN_TESTING,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-admin.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_ADMIN_SURVEY}`,
    name: Route.KNOWLEDGE_BASE_ADMIN_SURVEY,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-admin.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_ARTICLE}/:id`,
    name: Route.KNOWLEDGE_BASE_ARTICLE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/knowledge-base-article/knowledge-base-article-view.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_USER}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_USER,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-user.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
    name: Route.KNOWLEDGE_BASE_TESTING,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_RESPONDERS}/:id`,
    name: `/${Route.KNOWLEDGE_BASE_TESTING_RESPONDERS}/:id`,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-responder.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_CREATE}`,
    name: Route.KNOWLEDGE_BASE_TESTING_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_CREATE,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-create.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_EDIT}/:id`,
    name: `Route.KNOWLEDGE_BASE_TESTING_EDIT/:id`,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_EDIT,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-edit.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_PREVIEW}`,
    name: Route.KNOWLEDGE_BASE_TESTING_PREVIEW,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-preview.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_RESULTS}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_RESULTS,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-results.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_STATS,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-stats.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id/:userId`,
    name: `${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id/:userId`,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-responder-result.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_START}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_START,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-start.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_CHECKING}/:id/:userId`,
    name: Route.KNOWLEDGE_BASE_TESTING_CHECKING,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-checking.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
    name: Route.KNOWLEDGE_BASE_SURVEY,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_STATS,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-stats.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id/:userId`,
    name: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id/:userId`,
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-responder-result.vue"
      ),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_CREATE}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_CREATE,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-create.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_EDIT}/:id`,
    name: `Route.KNOWLEDGE_BASE_SURVEY_EDIT/:id`,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_EDIT,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-edit.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_PREVIEW}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_PREVIEW,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-preview.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_START}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_START,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-start.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_RESULTS}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_RESULTS,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-results.vue"
      ),
  },
  {
    path: `/${Route.USER_PROFILE}`,
    name: Route.USER_PROFILE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.NONE],
    },
    component: () =>
      import(
        "@/modules/user-cabinet/views/profile/user-profile/user-profile-view.vue"
      ),
  },
  {
    path: `/${Route.ADMIN_PROFILE}`,
    name: Route.ADMIN_PROFILE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.NONE],
    },
    component: () =>
      import(
        "@/modules/user-cabinet/views/profile/admin-profile/admin-profile-view.vue"
      ),
  },
  {
    path: `/${Route.COMMENTS}`,
    name: Route.COMMENTS,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/user-cabinet/views/comments/comments-view.vue"),
  },
  {
    path: `/${Route.ADMIN_MANAGEMENT}`,
    name: Route.ADMIN_MANAGEMENT,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/user-cabinet/views/admin-management/admin-management.vue"
      ),
  },
];
