import type { TabItem } from "@/shared/UIKit/app-tabs/app-tabs.types";

export enum TestingTab {
  TESTS_LIST = "list",
  RESULTS_BY_RESPONDERS = "results",
}

export const TABS: TabItem[] = [
  {
    text: "Список тестов",
    value: TestingTab.TESTS_LIST,
  },
  {
    text: "Результаты по респондентам",
    value: TestingTab.RESULTS_BY_RESPONDERS,
  },
];
