<template>
  <app-dialog v-model="isOpen" class="delete-dialog">
    <div class="flex flex-col items-start">
      <div
        class="mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-tomato/10"
      >
        <img alt="" src="@/assets/icons/danger.svg" />
      </div>
      <h4 class="mb-1 text-18 font-semibold">Удалить {{ currentEntity }}</h4>
      <p class="text-14 font-medium text-haze">
        Это действие нельзя будет отменить
      </p>
    </div>
    <template #footer>
      <div class="flex justify-end">
        <app-button
          class="!bg-transparent !text-16 !text-primary"
          size="small"
          @click="cancel"
          >Отменить
        </app-button>
        <app-button
          class="!bg-transparent !text-16 !text-tomato"
          size="small"
          @click="agree"
          >Удалить
        </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { storeToRefs } from "pinia";
import { useConfirmDialogStore } from "@/stores/confirm-dialog";

const { isOpen, currentEntity, resolve } = storeToRefs(useConfirmDialogStore());

const cancel = () => {
  resolve.value(false);
  isOpen.value = false;
};

const agree = () => {
  resolve.value(true);
  isOpen.value = false;
};
</script>

<style lang="scss">
.delete-dialog {
  .app-dialog-close {
    display: none;
  }
}
</style>
