<template>
  <app-popover v-model="model" :placement="placement">
    <slot>
      <app-button icon size="small" type="texted" variant="tertiary">
        <img
          alt="..."
          class="img--hoar cursor-pointer"
          src="@/assets/icons/kebab.svg"
        />
      </app-button>
    </slot>
    <template #content="{ close }">
      <ul
        :class="listStyles"
        class="flex flex-col gap-0.5 rounded-[10px] border border-lunar bg-white p-1.5 shadow-sneaky-peaky"
      >
        <li v-for="option in filteredOptions">
          <button
            class="flex min-h-9 w-full items-center gap-3 rounded-[10px] px-2 pr-[26px] text-13 font-medium hover:bg-hoar/10 active:bg-hoar/15"
            @click="
              () => {
                close();
                option.callback(meta);
              }
            "
          >
            <img
              v-if="option.icon"
              :src="option.icon"
              alt="Ред."
              class="img--hoar h-[18px] w-[18px]"
            />
            <span class="whitespace-nowrap">{{ option.text }}</span>
          </button>
          <div v-if="option.isDivided" class="-mx-[6px] mb-1 mt-1.5">
            <div class="h-px w-full bg-lunar" />
          </div>
        </li>
      </ul>
    </template>
  </app-popover>
</template>

<script lang="ts" setup>
import type { MenuOption } from "@/shared/UIKit/app-menu/app-menu.types";
import type { Placement } from "@floating-ui/vue";
import { computed } from "vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import AppPopover from "@/shared/UIKit/app-popover.vue";

interface Props {
  options: MenuOption[];
  placement?: Placement;
  listStyles?: string;
  meta?: any;
}

const props = withDefaults(defineProps<Props>(), {
  placement: "bottom",
  listStyles: "",
});

const model = defineModel({ default: false });

const filteredOptions = computed((): MenuOption[] => {
  return props.options.filter((item) => !item.shouldHide);
});
</script>

<style lang="scss" scoped></style>
