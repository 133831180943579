<template>
  <app-dialog v-model="model" :no-content="true" width="320">
    <template #header> Вы уверены, что хотите выйти?</template>
    <template #footer>
      <app-button
        class="ml-auto"
        type="texted"
        variant="primary"
        @click="model = false"
      >
        Отменить
      </app-button>
      <app-button type="texted" variant="primary" @click="handleExitBtnClick">
        Выйти
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { defineModel } from "vue";
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user/user";

const model = defineModel<boolean>({ required: true });
const router = useRouter();
const userStore = useUserStore();

const handleExitBtnClick = async () => {
  // const response = await authApi.common.logout();
  userStore.logout();
};
</script>

<style lang="scss" scoped></style>
