export enum Route {
  AUTH = "auth",
  USER_CONFIRM_TOKEN = "auth/user-confirm/token",
  ADMIN_CONFIRM_TOKEN = "auth/admin-confirm/token",
  REGISTER = "register",
  RESET = "reset",
  NEW_PASSWORD = "new-password/token",
  CONFIRM_PHONE = "confirm-phone",

  PAYMENT_SUCCESS = "payment-success",

  KNOWLEDGE_BASE = "knowledge-base",
  KNOWLEDGE_BASE_ADMIN = "knowledge-base-adminbb",
  KNOWLEDGE_BASE_ADMIN_TESTING = "knowledge-base-adminbb/testing",
  KNOWLEDGE_BASE_ADMIN_SURVEY = "knowledge-base-adminbb/survey",

  KNOWLEDGE_BASE_ARTICLE = "knowledge-base/article",

  KNOWLEDGE_BASE_TESTING = "knowledge-base/testing",
  KNOWLEDGE_BASE_TESTING_CREATE = "knowledge-base/testing/create",
  KNOWLEDGE_BASE_TESTING_EDIT = "knowledge-base/testing/edit",
  KNOWLEDGE_BASE_TESTING_PREVIEW = "knowledge-base/testing/preview",
  KNOWLEDGE_BASE_TESTING_START = "knowledge-base/testing/start",
  KNOWLEDGE_BASE_TESTING_RESULTS = "knowledge-base/testing/results",
  KNOWLEDGE_BASE_TESTING_RESPONDERS = "knowledge-base/testing/responders",
  KNOWLEDGE_BASE_TESTING_STATS = "knowledge-base/testing/stats",
  KNOWLEDGE_BASE_TESTING_CHECKING = "knowledge-base/testing/checking",

  KNOWLEDGE_BASE_SURVEY_USER = 'knowledge-base/survey-user',

  KNOWLEDGE_BASE_SURVEY = "knowledge-base/survey",
  KNOWLEDGE_BASE_SURVEY_CREATE = "knowledge-base/survey/create",
  KNOWLEDGE_BASE_SURVEY_EDIT = "knowledge-base/survey/edit",
  KNOWLEDGE_BASE_SURVEY_PREVIEW = "knowledge-base/survey/preview",
  KNOWLEDGE_BASE_SURVEY_START = "knowledge-base/survey/start",
  KNOWLEDGE_BASE_SURVEY_RESULTS = "knowledge-base/survey/results",
  KNOWLEDGE_BASE_SURVEY_STATS = "knowledge-base/survey/stats",

  USER_PROFILE = "profile",

  ADMIN_PROFILE = "admin-profile",
  COMMENTS = "comments",
  ADMIN_MANAGEMENT = "admin-management",
}
