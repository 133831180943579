import axios, { type AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  adapter: "fetch",
  timeout: 5000,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
