import { DocumentType } from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import articleIcon from "@/assets/icons/file-text.svg";
import folderIcon from "@/assets/icons/folder.svg";

export const getCourseIcon = (docType: DocumentType) => {
  switch (docType) {
    case DocumentType.Article:
      return articleIcon;
    case DocumentType.Folder:
      return folderIcon;
  }
};

export const getNewTitle = (docType: DocumentType) => {
  switch (docType) {
    case DocumentType.Folder:
      return "Новый курс";
    case DocumentType.Article:
      return "Новый урок";
  }
};
