import type { Position } from "@vue-flow/core";

export enum SnackbarStatus {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARN = "warn",
}

export interface Snackbar {
  id: number;
  text: string;
  position: Position;
  status: SnackbarStatus;
  timeout: number;
}
