<template>
  <div class="h-screen w-screen">
    <app-header v-show="!store.isFullScreen" />
    <div
      :class="{ 'h-[calc(100vh-52px)] p-5': !store.isFullScreen }"
      class="relative flex gap-5"
    >
      <app-navigation :class="{ '-translate-x-[368px]': !isSidebarOpen }" />
      <div
        :class="{
          'ml-[348px]': isSidebarOpen,
        }"
        class="flex w-full grow flex-col overflow-hidden"
      >
        <slot />
      </div>
      <app-snackbar />
      <delete-dialog />
      <confirm-dialog />
      <top-up-balance-dialog />
      <access-denied-dialog />
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppNavigation from "@/layouts/navigation/app-navigation.vue";
import AppSnackbar from "@/shared/UIKit/app-snackbar.vue";
import DeleteDialog from "@/features/dialogs/delete-dialog.vue";
import ConfirmDialog from "@/layouts/confirm-dialog.vue";
import AppHeader from "@/layouts/headers/app-header.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { useRoute } from "vue-router";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import AccessDeniedDialog from "@/layouts/access-denied-dialog.vue";
import TopUpBalanceDialog from "@/modules/user-cabinet/views/profile/components/dialogs/top-up-balance-dialog.vue";
import { computed } from "vue";

const store = useSidebarStore();
const route = useRoute();

const isSidebarOpen = computed(() => {
  return (
    !store.isFullScreen &&
    route.meta.sidebars &&
    !route.meta.sidebars.includes(SidebarType.NONE)
  );
});
</script>

<style lang="scss" scoped></style>
