import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { Mark } from "prosemirror-model";
import type { ArticleResponse } from "@boostbiz/wiki_client/schemas/articles/articles.schema";
import type { CreateQuestionFormDTO } from "@boostbiz/testing_form_microservice/client/schemas";
import type {
  CreateQuestionFormDTO as CreateSurveyDTO,
  CreateQuestionFormTemplateDTO,
} from "@boostbiz/question_form_microservice/client/schemas";
import {
  getDefaultsTestData,
  getDefaultsTestTemplateData,
} from "@/modules/knowledge-base/views/testing-survey/testing/testing.utils";
import type CreateForm from "@/modules/knowledge-base/views/testing-survey/testing/create-form.vue";
import QuestionsList from "@/modules/knowledge-base/views/testing-survey/testing/questions-list.vue";
import {
  getDefaultsSurveyData,
  getDefaultsSurveyTemplateData,
} from "@/modules/knowledge-base/views/testing-survey/survey/survey.utils";
import { wikiApi } from "@/api";
import type { UserCourseTree } from "@boostbiz/wiki_client/schemas/courses/courses.schema";
import type { ArticleBreadcrumb } from "@/modules/knowledge-base/views/knowledge-base-article/components/article-breadcrumbs.data";
import { DocumentType } from "@boostbiz/wiki_client/schemas/documents/documents.schema";

export const useKnowledgeBaseStore = defineStore("knowledge", () => {
  const editor = ref<{ storedMarks?: readonly Mark[] | null }>({
    storedMarks: null,
  });
  const courseTree = ref<UserCourseTree[]>([]);
  const mainUpdateToggle = ref(false);
  const sidebarUpdateToggle = ref(false);
  const search = ref("");
  const currentArticle = ref<ArticleResponse | null>(null);
  const collapsedDocumentsIds = ref<number[]>([]);
  const articleBreadcrumbs = ref<ArticleBreadcrumb[]>([
    {
      title: "Главная",
      id: 0,
    },
  ]);

  const test = ref<CreateQuestionFormDTO>(getDefaultsTestData());
  const testFormRef = ref<InstanceType<typeof CreateForm>>();
  const testQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const testPreview = ref<CreateQuestionFormDTO>(/*getDefaultsTestData()*/);

  const testTemplate = ref<CreateQuestionFormTemplateDTO>(
    getDefaultsTestTemplateData(),
  );
  const testTemplateFormRef = ref<InstanceType<typeof CreateForm>>();
  const testTemplateQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const survey = ref<CreateSurveyDTO>(getDefaultsSurveyData());
  const surveyFormRef = ref<InstanceType<typeof CreateForm>>();
  const surveyQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const surveyPreview = ref<CreateSurveyDTO>(/*getDefaultsSurveyData()*/);

  const surveyTemplate = ref<CreateQuestionFormTemplateDTO>(
    getDefaultsSurveyTemplateData(),
  );
  const surveyTemplateFormRef = ref<InstanceType<typeof CreateForm>>();
  const surveyTemplateQuestionsRef = ref<InstanceType<typeof QuestionsList>>();

  const fetchCourseTree = async () => {
    courseTree.value = await wikiApi.getCourseTree();
  };

  const searchCourses = async () => {
    return await wikiApi.searchCourses(search.value);
  };

  const setEditor = (val: { storedMarks?: readonly Mark[] }) => {
    editor.value = val;
  };

  const nextArticle = computed(() => {
    function findNextCourse(courses: UserCourseTree[] | UserCourseTree['subDocuments'], currentId: string): UserCourseTree | null {
      let foundCurrent = false;

      for (const course of courses) {
        if (foundCurrent && course.documentType === DocumentType.Article) {
          return course as UserCourseTree;
        }

        if (course.id === currentId) {
          foundCurrent = true;
        }

        if (!foundCurrent && course.subDocuments) {
          const nextCourse = findNextCourse(course.subDocuments, currentId);
          if (nextCourse) return nextCourse;
        }
      }

      return null;
    }

    return findNextCourse(courseTree.value, currentArticle.value?.id ?? '');
  });




  //TODO Взаимодействовать с опросниками/тестированием ерез стор

  return {
    editor,
    setEditor,
    search,
    courseTree,
    collapsedDocumentsIds,
    mainUpdateToggle,
    sidebarUpdateToggle,
    currentArticle,
    articleBreadcrumbs,
    fetchCourseTree,
    searchCourses,
    test,
    testFormRef,
    testQuestionsRef,
    survey,
    surveyFormRef,
    surveyQuestionsRef,
    surveyTemplate,
    surveyTemplateFormRef,
    surveyTemplateQuestionsRef,
    surveyPreview,
    testPreview,
    testTemplate,
    testTemplateFormRef,
    testTemplateQuestionsRef,
    nextArticle
  };
});
