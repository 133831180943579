<template>
  <component
    :is="resolveComponent()"
    :class="[`app-navigation-item--${item.type}`]"
    :style="{ paddingLeft: 10 + 26 * level + 'px' }"
    :to="item.link || ''"
    class="app-navigation-item"
    @click="handleRouteLinkClick"
  >
    <img
      :class="{ '!img--primary': item.type === 'primary' }"
      :src="item.icon"
      alt=""
      class="img--hoar app-navigation-item--icon"
    />
    <span class="text-14 font-semibold">{{ item.title }}</span>

    <button v-if="item.sidebar" class="ml-auto">
      <img
        alt=""
        class="img--hoar h-[18px] w-[18px] rotate-0"
        src="@/assets/icons/direction/arrow-right-simple.svg"
      />
    </button>

    <button v-if="item.subItems" class="ml-auto">
      <img
        :class="{ '!rotate-180': isSubItemsOpen }"
        alt=""
        class="img--primary h-[18px] w-[18px] duration-200"
        src="@/assets/icons/direction/arrow-circle.svg"
      />
    </button>
  </component>

  <template v-if="item.subItems && isSubItemsOpen">
    <app-navigation-item
      v-for="subItem in item.subItems"
      :key="subItem.title"
      :item="subItem"
      :level="level + 1"
    />
  </template>
</template>

<script lang="ts" setup>
import type { NavigationItem } from "@/layouts/navigation/navigation.data";
import { ref } from "vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { RouterLink } from "vue-router";

const sidebarStore = useSidebarStore();

interface Props {
  item: NavigationItem;
  level?: number;
}

const props = withDefaults(defineProps<Props>(), {
  level: 0,
});

const isSubItemsOpen = ref(false);

const handleArrowButtonClick = () => {
  isSubItemsOpen.value = !isSubItemsOpen.value;
};

const handleRouteLinkClick = (event: Event) => {
  if (!props.item.link && props.item.subItems) {
    event.preventDefault();

    handleArrowButtonClick();
  }

  if (props.item.sidebar) {
    sidebarStore.currentSidebars.push(props.item.sidebar);
  }
};

const resolveComponent = () => {
  if (props.item.link) {
    return RouterLink;
  }
  return "button";
};
</script>

<style lang="scss" scoped>
.app-navigation-item {
  &--primary {
    @apply text-primary hover:text-primary;
  }

  @apply flex cursor-pointer items-center gap-2
  rounded-[10px] px-2.5 py-2 text-hoar duration-200
  hover:bg-hoar/10 active:bg-hoar/15 hover:text-base h-9;

  &.router-link-exact-active {
    @apply bg-background text-base #{!important};
    .app-navigation-item--icon {
      // TODO  заменить на img--primary
      filter: invert(34%) sepia(81%) saturate(2181%) hue-rotate(204deg)
        brightness(97%) contrast(96%);
    }

    .app-navigation-item--primary {
      @apply text-primary #{!important};
    }
  }
}
</style>
