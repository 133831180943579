import axiosInstance from "./api-instance";

import { AuthAPI } from "@boostbiz/partners_auth";
import { TestingAPI } from "@boostbiz/partners_testing_form_microservice";
import { QuestionFormAPI as SurveyAPI } from "@boostbiz/partners_servey_service";
import { PartnerWikiAPIClient } from "@boostbiz/partner_wiki_client";
import { BoostAIAPI } from "@boostbiz/flg_ai";
import { PaymentAPIClient } from "@boostbiz/payment_client";
import { PartnersVideoProcessingAPI } from "@boostbiz/partners_video_processing_client";
import { KnowledgeBaseCommentsAPI } from "@boostbiz/partners_comments-microservice";

export const paymentApi = new PaymentAPIClient(axiosInstance);
export const wikiApi = new PartnerWikiAPIClient(axiosInstance);
export const gptApi = new BoostAIAPI(axiosInstance);
export const authApi = new AuthAPI(axiosInstance);
export const testingApi = new TestingAPI(axiosInstance);
export const surveyApi = new SurveyAPI(axiosInstance);
export const videoProcessingApi = new PartnersVideoProcessingAPI(axiosInstance);
export const commentsApi = new KnowledgeBaseCommentsAPI(axiosInstance);
