import { ref } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

export const useAccessDeniedDialogStore = defineStore(
  "access-denied-dialog",
  () => {
    const router = useRouter()

    const isOpen = ref(false);
    const redirectLink = ref("");

    const open = (link?: string) => {
      redirectLink.value = link || "";
      isOpen.value = true;
    };

    const close = async () => {
      if (redirectLink.value) {
        await router.push(redirectLink.value);
      }

      isOpen.value = false;

      redirectLink.value = ''
    };

    return {
      isOpen,
      open,
      close,
      redirectLink
    };
  },
);
