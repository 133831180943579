import { Route } from "@/router/router.types";
import { Layout } from "@/layouts/layouts.types";

export const userRoutes = [
  {
    path: `/${Route.AUTH}`,
    name: Route.AUTH,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
    },
    component: () => import("@/modules/user/views/auth-view.vue"),
  },
  {
    path: `/${Route.USER_CONFIRM_TOKEN}/:token`,
    name: Route.USER_CONFIRM_TOKEN,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
    },
    component: () => import("@/modules/user/views/user-invitation-confirm.vue"),
  },
  {
    path: `/${Route.REGISTER}`,
    name: Route.REGISTER,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
      showBackArrow: true,
    },
    component: () => import("@/modules/user/views/register-view.vue"),
  },
  {
    path: `/${Route.RESET}`,
    name: Route.RESET,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
      showBackArrow: true,
    },
    component: () => import("@/modules/user/views/password-reset-view.vue"),
  },
  {
    path: `/${Route.ADMIN_CONFIRM_TOKEN}/:token`,
    name: Route.ADMIN_CONFIRM_TOKEN,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
      showBackArrow: true,
    },
    component: () => import("@/modules/user/views/admin-invitation-confirm.vue"),
  },
  {
    path: `/${Route.NEW_PASSWORD}/:token`,
    name: Route.NEW_PASSWORD,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
    },
    component: () => import("@/modules/user/views/password-new-view.vue"),
  },
  {
    path: `/${Route.CONFIRM_PHONE}`,
    name: Route.CONFIRM_PHONE,
    meta: {
      layout: Layout.BLANK,
      showHeader: true,
      showBackArrow: true,
    },
    component: () => import("@/modules/user/views/confirm-phone-view.vue"),
  },
];
