import { ref } from "vue";
import { defineStore } from "pinia";
import { Snackbar, SnackbarStatus } from "@/stores/snackbar/snackbar.types";
import { Position } from "@vue-flow/core";

export const useSnackbarStore = defineStore("snackbar", () => {
  const snackbars = ref<Snackbar[]>([]);
  let nextId = 1;

  const showSnackbar = (
    text = "Неизвестная ошибка",
    status = SnackbarStatus.SUCCESS,
    position = Position.Top,
    timeout = 5000,
  ) => {
    const id = nextId++;
    const newSnackbar: Snackbar = {
      id,
      text,
      position,
      status,
      timeout,
    };
    snackbars.value.push(newSnackbar);

    setTimeout(() => {
      snackbars.value = snackbars.value.filter(
        (snackbar) => snackbar.id !== id,
      );
    }, timeout);
  };

  const closeSnackbar = (id: number) => {
    snackbars.value = snackbars.value.filter((snackbar) => snackbar.id !== id);
  };

  return {
    snackbars,
    showSnackbar,
    closeSnackbar,
  };
});
