<script lang="ts" setup>
import { RouterView, useRoute } from "vue-router";
import AppLayout from "@/layouts/app-layout.vue";
import { computed } from "vue";
import { Layout } from "@/layouts/layouts.types";
import BlankLayout from "@/layouts/blank-layout.vue";
import ru from "dayjs/locale/ru";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EmptyLayout from "@/layouts/empty-layout.vue";

dayjs.locale(ru);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
const route = useRoute();

const resolveLayout = computed(() => {
  if (Object.keys(route.meta).length === 0) {
    return null;
  }

  switch (route.meta.layout) {
    case Layout.BLANK:
      return BlankLayout;
    case Layout.EMPTY:
      return EmptyLayout;
    default:
      return AppLayout;
  }
});
</script>

<template>
  <component :is="resolveLayout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<style></style>
