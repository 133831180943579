import { ref } from "vue";
import { defineStore } from "pinia";

export const useDeleteDialogStore = defineStore("delete-dialog", () => {
  const isOpen = ref(false);
  const isLoading = ref(false);
  const currentEntity = ref("");
  const resolve = ref();

  const confirm = async (entityName: string, callback: any) => {
    currentEntity.value = entityName;

    isOpen.value = true;

    await callback(
      new Promise((res) => {
        resolve.value = res;
      }),
    );

    isLoading.value = false;
    isOpen.value = false;
  };

  return {
    isLoading,
    isOpen,
    currentEntity,
    resolve,
    confirm,
  };
});
