import { ref } from "vue";
import { defineStore } from "pinia";

export const useBalanceDialogStore = defineStore("balance-dialog", () => {
  const isOpen = ref(false);

  return {
    isOpen,
  };
});
