import "./assets/main.css";
import "@vuepic/vue-datepicker/dist/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import VueDatePicker from "@vuepic/vue-datepicker";
import { MotionPlugin } from "@vueuse/motion";

const app = createApp(App);
Sentry.init({
  app,
  dsn: import.meta.env.PROD
    ? "https://beff037ececd97da9a100092c85f92d5@o4506653814423552.ingest.sentry.io/4506654005395457"
    : "",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(MotionPlugin);

app.use(createPinia());
app.use(router);

app.component("VueDatePicker", VueDatePicker);

app.mount("#app");
