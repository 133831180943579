<template>
  <TransitionRoot :show="model || permanent" as="template">
    <Dialog @close="handleDialogClose" as="div" class="relative z-[100]">
      <TransitionChild
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-transparent" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          :class="{ 'pointer-events-none': disabled }"
          class="flex min-h-full items-center justify-center overflow-hidden bg-base/70 backdrop-blur-[3px]"
        >
          <TransitionChild
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :style="{
                width: width + 'px',
                height: height ? height + 'px' : '',
              }"
              class="app-dialog--panel pointer-events-auto flex w-[600px] transform flex-col rounded-3 bg-white transition-all"
            >
              <button
                v-if="showCloseIcon"
                class="absolute -right-[40px] top-0 flex h-7 w-7 items-center justify-center rounded-full bg-white"
                @click="model = false"
              >
                <img
                  alt="+"
                  class="img--hoar"
                  src="@/shared/UIKit/app-dialog/icons/close.svg"
                />
              </button>

              <div
                v-if="$slots.header"
                :class="{ 'border-b border-lunar': !noContent }"
                class="flex h-[56px] min-h-[56px] items-center px-6 py-1 text-16 font-semibold"
              >
                <app-button
                  v-if="canBack"
                  class="-ml-2 mr-2"
                  icon
                  size="medium"
                  type="texted"
                  variant="tertiary"
                  @click="emit('back')"
                >
                  <img
                    alt="<-"
                    class="img--hoar"
                    src="@/assets/icons/direction/arrow-left.svg"
                  />
                </app-button>
                <slot name="header"></slot>
              </div>
              <div
                v-if="!noContent"
                class="flex flex-grow flex-col gap-5 overflow-y-auto px-6 py-4"
              >
                <slot />
              </div>
              <div
                v-if="$slots.footer"
                :class="{ 'border-t border-lunar': !noContent }"
                class="flex h-[56px] min-h-[56px] p-2"
              >
                <slot name="footer" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";

interface Props {
  showCloseIcon?: boolean;
  keepMounted?: boolean;
  width?: string | number;
  height?: string | number;
  canBack?: boolean;
  permanent?: boolean;
  disabled?: boolean;
  noContent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showCloseIcon: false,
  keepMounted: false,
  width: 600,
  canBack: false,
  permanent: false,
  disabled: false,
  noContent: false,
});

const emit = defineEmits<{
  back: [];
}>();

const model = defineModel<boolean>();

const handleDialogClose = () => {
  if (!props.permanent)  {
    model.value = false
  }
}

</script>
