<template>
  <ul class="flex flex-col gap-[1px]">
    <li
      v-for="tab in TABS"
      :key="tab.text"
      :class="{ '!bg-background !text-base': tab.value === currentTab }"
      class="flex cursor-pointer items-center gap-2 rounded-[10px] px-2.5 py-2 text-hoar duration-200 hover:bg-background hover:text-base h-9"
      @click="handleUpdateTab(tab)"
    >
      <img
        :class="{ '!img--primary': tab.value === currentTab }"
        :src="tab.icon"
        alt=""
        class="img--hoar"
      />
      <span class="text-14 font-semibold">{{ tab.text }}</span>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import {
  SettingsTab,
  TABS,
} from "@/layouts/sidebars/knowledge-base-create-sidebar/knowledge-testing-survey-sidebar.data";
import type { TabItem } from "@/shared/UIKit/app-tabs/app-tabs.types";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const currentTab = ref();

const handleUpdateTab = (tab: TabItem) => {
  currentTab.value = tab.value;
};

watch(
  () => route.query.tab,
  () => {
    currentTab.value = route.query.tab?.toString() || SettingsTab.SETTINGS;
  },
  {
    immediate: true,
  },
);

watch(currentTab, () => {
  router.push({
    query: {
      ...route.query,
      tab: currentTab.value,
    },
  });
});
</script>

<style lang="scss" scoped></style>
