import type { TabItem } from "@/shared/UIKit/app-tabs/app-tabs.types";
import settingsIcon from "@/assets/icons/knowledge-settings.svg";
import questionsIcon from "@/assets/icons/knowledge-questions.svg";
import eyeIcon from "@/assets/icons/eye.svg";
import notebookIcon from "@/assets/icons/knowledge-base-notebook-pen.svg";
import createIcon from "@/assets/icons/knowledge-base-create.svg";

export enum SettingsTab {
  SETTINGS = "settings",
  QUESTIONS = "questions",
}

export enum CreateAction {
  PREVIEW = "preview",
  DRAFT = "draft",
  CREATE = "create",
}

export enum EditAction {
  PREVIEW = "preview",
  START = "start",
  EDIT = "edit",
}

export const TABS: TabItem[] = [
  {
    text: "Настройки",
    value: SettingsTab.SETTINGS,
    icon: settingsIcon,
  },
  {
    text: "Вопросы",
    value: SettingsTab.QUESTIONS,
    icon: questionsIcon,
  },
];

export const CREATE_ACTIONS: TabItem[] = [
  {
    text: "Предпросмотр",
    value: CreateAction.PREVIEW,
    icon: eyeIcon,
  },
  {
    text: "В черновик",
    value: CreateAction.DRAFT,
    icon: notebookIcon,
  },
  {
    text: "Создать",
    value: CreateAction.CREATE,
    icon: createIcon,
  },
];

export const EDIT_ACTIONS: TabItem[] = [
  {
    text: "Предпросмотр",
    value: EditAction.PREVIEW,
    icon: eyeIcon,
  },
  {
    text: "Редактировать",
    value: EditAction.EDIT,
    icon: createIcon,
  },
  {
    text: "Запустить",
    value: EditAction.START,
    icon: notebookIcon,
  },
];

export const CREATE_TEMPLATE_ACTIONS: TabItem[] = [
  {
    text: "Предпросмотр",
    value: CreateAction.PREVIEW,
    icon: eyeIcon,
  },
  {
    text: "Создать",
    value: CreateAction.CREATE,
    icon: createIcon,
  },
];

export const EDIT_TEMPLATE_ACTIONS: TabItem[] = [
  {
    text: "Предпросмотр",
    value: EditAction.PREVIEW,
    icon: eyeIcon,
  },
  {
    text: "Редактировать",
    value: EditAction.EDIT,
    icon: createIcon,
  },
];
