<template>
  <app-dialog v-model="balanceDialogStore.isOpen" width="400">
    <template #header> Пополнение баланса</template>
    <app-input v-model="sum" label="Сумма пополнения" type="number" />
    <template #footer>
      <app-button
        :loading="isLoading"
        class="w-full"
        type="texted"
        variant="primary"
        @click="handleTopUpBalanceBtnClick"
      >
        Пополнить
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import AppInput from "@/shared/UIKit/app-input.vue";
import { paymentApi } from "@/api";
import { useBalanceDialogStore } from "@/stores/dialogs/balance-dialog";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import axios from "axios";

const sum = ref(0);
const isLoading = ref(false);
const balanceDialogStore = useBalanceDialogStore();
const { showSnackbar } = useSnackbarStore();

const handleTopUpBalanceBtnClick = async () => {
  isLoading.value = true;

  try {
    const response = await paymentApi.initPartnersTinkoffPayment({
      amount: sum.value,
    });
    window.open(response.paymentUrl, "blank");

    balanceDialogStore.isOpen = false;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      showSnackbar(error.response.data.message, SnackbarStatus.ERROR);
    }
  }

  isLoading.value = false;
};
</script>

<style lang="scss" scoped></style>
