<template>
  <navigation-layout
    :level="1"
    is-sub-layout
    @click-title="router.push(`/${Route.KNOWLEDGE_BASE}`)"
  >
    <template #header>
      <p class="text-16 font-medium">Библиотека</p>
    </template>

    <div class="flex flex-col gap-0.5 p-[6px]">
      <app-navigation-item
        v-for="item in routes"
        :key="item.title"
        :item="item"
      />
    </div>

    <div
      v-if="userStore.user?.owner"
      class="flex min-h-10 items-center justify-between border-b border-t border-lunar pl-4 pr-[9px]"
    >
      <span class="text-12 font-semibold text-hoar">Курсы</span>
    </div>
    <div v-else class="h-px w-full bg-lunar" />

    <div class="h-full overflow-y-auto">
      <div class="flex flex-col">
        <knowledge-base-navigation-item
          v-for="courseTree in sidebarCourses"
          :key="courseTree.id"
          v-model="collapsedCoursesIds"
          :item="courseTree"
          :items="sidebarCourses"
        />
      </div>
    </div>
  </navigation-layout>
</template>
<script lang="ts" setup>
import notebookIcon from "@/assets/icons/knowledge-base-notebook.svg";
import notebookOpenIcon from "@/assets/icons/notebook-open.svg";
import notebookNotifyIcon from "@/assets/icons/knowledge-base-notebook-notify.svg";
import { Route } from "@/router/router.types";
import { computed, nextTick, ref, watch } from "vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";
import { getCourseIcon, getNewTitle } from "@/views/knowledge-base/utils";
import { DocumentType } from "@boostbiz/wiki_client/schemas/documents/documents.schema";
import type { MenuOption } from "@/shared/UIKit/app-menu/app-menu.types";
import KnowledgeBaseNavigationItem from "@/views/knowledge-base/components/knowledge-base-navigation/knowledge-base-navigation-item.vue";
import cloneDeep from "clone-deep";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { useRouter } from "vue-router";
import { TestingTab } from "@/modules/knowledge-base/views/testing-survey/testing/tabs/testing-tabs.data";
import type { UserCourseTree } from "@boostbiz/wiki_client/schemas/courses/courses.schema";
import AppNavigationItem from "@/layouts/navigation/app-navigation-item.vue";
import adminPanelIcon from "@/assets/icons/layers.svg";
import { useUserStore } from "@/stores/user/user";
import comments from "@/assets/icons/comments.svg";
import userDoubleIcon from "@/assets/icons/user/user-double.svg";

const sidebarStore = useSidebarStore();
const { currentSidebars } = storeToRefs(sidebarStore);
const userStore = useUserStore();
const router = useRouter();
const knowledgeBaseStore = useKnowledgeBaseStore();
const sidebarCourses = ref<UserCourseTree[]>(
  cloneDeep(knowledgeBaseStore.courseTree),
);
const collapsedCoursesIds = ref<number[]>([]);

watch(
  () => knowledgeBaseStore.mainUpdateToggle,
  async () => {
    sidebarCourses.value = cloneDeep(knowledgeBaseStore.courseTree);
  },
);

knowledgeBaseStore.fetchCourseTree().then(() => {
  knowledgeBaseStore.mainUpdateToggle = !knowledgeBaseStore.mainUpdateToggle;
  knowledgeBaseStore.sidebarUpdateToggle =
    !knowledgeBaseStore.sidebarUpdateToggle;
});


const routes = computed(() => {
  const routes = [
    {
      title: "База курсов",
      icon: notebookOpenIcon,
      link: {
        path: `/${Route.KNOWLEDGE_BASE}`,
      },
    },
  ];

  if (!userStore.user) {
    return routes;
  }

  if (userStore.user.owner) {
    return [
      ...routes,
      {
        title: "Тестирования",
        icon: notebookIcon,
        link: {
          path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
          query: {
            tab: TestingTab.TESTS_LIST,
            page: 1,
            count: 10,
            search: "",
          },
        },
      },
      {
        title: "Опросы",
        icon: notebookNotifyIcon,
        link: {
          path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
          query: {
            page: 1,
            count: 10,
            search: "",
          },
        },
      },
      {
        title: "Админ панель",
        icon: adminPanelIcon,
        subItems: [
          {
            title: "Управление админами",
            icon: userDoubleIcon,
            link: `/${Route.ADMIN_MANAGEMENT}`,
          },
          {
            title: "Комментарии",
            icon: comments,
            link: {
              path: `/${Route.COMMENTS}`,
              query: {
                page: 1,
                count: 10,
              },
            },
          },
          {
            title: "Курсы",
            icon: notebookOpenIcon,
            link: {
              path: `/${Route.KNOWLEDGE_BASE_ADMIN}`,
            },
          },
          {
            title: "Опросы",
            icon: notebookNotifyIcon,
            link: {
              path: `/${Route.KNOWLEDGE_BASE_ADMIN_SURVEY}`,
              query: {
                page: 1,
                count: 10,
                search: "",
              },
            },
          },
        ],
      },
    ];
  } else {
    return [
      ...routes,
      {
        title: "Опросы",
        icon: notebookNotifyIcon,
        link: {
          path: `/${Route.KNOWLEDGE_BASE_SURVEY_USER}`,
        },
      },
    ];
  }
});
</script>

<style lang="scss" scoped></style>
