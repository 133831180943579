<template>
  <app-dialog
    @update:model-value="handleUpdateModelValue"
    :width="400"
    :permanent="isOpen"
    v-model="isOpen"
  >
    <div class="flex flex-col items-center gap-4 pt-2">
      <img
        src="@/assets/icons/access-denied.svg"
        class="img--primary h-11 w-11"
        alt=""
      />
      <div class="flex max-w-[280px] flex-col items-center gap-2 text-center">
        <p class="text-16 font-semibold">Нет доступа</p>
        <span class="text-14 font-medium text-hoar"
          >У вас не достаточно прав для просмотра этой страницы.</span
        >
      </div>
      <div
        class="jusitfy-center -mx-6 -mb-3 flex w-[calc(100%+48px)] items-center border-t border-t-lunar px-1 pt-1"
      >
        <app-button
          @click="accessDeniedDialogStore.close()"
          type="texted"
          class="w-full text-center"
        >
          Закрыть
        </app-button>
      </div>
    </div>
  </app-dialog>
</template>

<script setup lang="ts">
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import { useAccessDeniedDialogStore } from "@/stores/access-denied-dialog";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { storeToRefs } from "pinia";
const accessDeniedDialogStore = useAccessDeniedDialogStore();

const { isOpen } = storeToRefs(accessDeniedDialogStore);

const handleUpdateModelValue = (value: boolean) => {
  if (!value) {
    accessDeniedDialogStore.close();
  }
};
</script>

<style scoped lang="scss"></style>
